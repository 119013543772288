import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'

import { Title } from './style'
import Heading from '../common/heading'
import { ButtonFromBackend } from '../common/button'
import { authorize, isLoggedIn } from '../../services/authentication'

const Hero = ({
  titleText,
  titleTag,
  subtitleText,
  subtitleTag,
  buttons,
  image,
}) => {
  return (
    <>
      <div
        className={`
      w-full
      h-full
      block
      relative
      overflow-hidden
      bg-blue-lighter
      `}
      >
        <div className='xl-container mx-auto grid grid-cols-1 lg:grid-cols-6 pt-8 gap-8 lg:gap-0 items-end'>
        <div
          className={`lg:col-span-4 h-full flex flex-col items-start justify-start lg:justify-end pt-8 lg:pt-0 relative
          `}
        >
          <div className={'overflow-hidden h-max mb-6 md:mb-3 lg:mb-8'}>
            <Title component={titleTag}> {titleText} </Title>
          </div>
          <Heading
            component={subtitleTag}
            className={
              'max-w-2xl text-left text-base md:text-[19px] md:leading-7 lg:text-[21px] lg:leading-8 max-w-3xl [&>a]:border-b-2 [&>a]:border-orange-main hover:[&>a]:border-orange-lightHover'
            }
          >
            {parse(subtitleText)}
          </Heading>
          <div
            className={
              'flex flex-nowrap w-full justify-start gap-x-2 pt-6 lg:py-8 z-[25]'
            }
          >
            {isLoggedIn() && (
              <>
                <button
                  className={
                    'text-center font-medium w-max  pt-[14px] pb-[16px] px-10  md:pt-[17.5px]  md:pb-[19.5px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-orange-main text-white hover:bg-orange-light disabled:bg-gray-200 disabled:text-gray-700'
                  }
                  onClick={() => authorize()}
                >
                  Gå til Proteket Online
                </button>
              </>
            )}
            {buttons && !isLoggedIn() && (
              <>
                {buttons.map((item, index) => {
                  return (
                    <ButtonFromBackend
                      key={index}
                      path={item.link.url}
                      variant={item.variant}
                      isExternal={false}
                      target={item.link.target}
                      islarge={'true'}
                    >
                      {item.link.title}
                    </ButtonFromBackend>
                  )
                })}
              </>
            )}
          </div>
        </div>
        {image && (
            <img src={image.sourceUrl} alt={image.alt} className={'lg:col-span-2 w-full h-auto object-contain'} />
          )}
      </div>
      </div> 
    </>
  )
}

export default Hero

Hero.propTypes = {
  titleText: PropTypes.string,
  titleTag: PropTypes.string,
  subtitleText: PropTypes.string,
  subtitleTag: PropTypes.string,
  buttons: PropTypes.array,
  image: PropTypes.object,
}