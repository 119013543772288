import React, { useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import parse from 'html-react-parser'

const StepSlider = ({ list }) => {
  const [slides, setSlides] = useState(0)
  const [index, setIndex] = useState(0)
  const ref = useRef()

  useEffect(() => {
    if (ref.current) {
      setSlides(ref.current.splide.length)
    }
  }, [])
  return (
    <>
      <Splide
        id={'steps'}
        onMoved={(splide, newIndex) => {
          setIndex(newIndex)
        }}
        className={'mx-4 md:mx-0'}
        ref={ref}
        tag={'section'}
        hasTrack={false}
        options={{
          direction: 'ttb',
          perPage: 1,
          height: '26rem',
          gap: '2rem',
          autoHeight: true,
          trimSpace: false,
          easing: 'ease-out',
          focus: 'center',
          start: '0',
          arrows: false,
          classes: {
            pagination: 'splide__pagination your-class-pagination',
          },
          breakpoints: {
            767: {
              height: '32rem',
            },
          }
        }}
      >
        <div>
          <SplideTrack className={'pb-4'}>
            {list.map((item, key) => (
              <SplideSlide key={key} className={'self-center h-full'}>
                <div
                  className={`grid md:grid-cols-2 gap-4 h-full`}
                >
                  <img
                    className={'rounded-xl w-full object-cover max-h-full self-center overflow-hidden'}
                    src={item.stepImage.publicUrl}
                    alt={item.stepImage.altText ? item.stepImage.altText : ''}
                  />
                  <div className={'flex flex-col justify-center p-4 text-center md:text-left'}>
                    <h3 className={'text-2xl font-bold mb-4'}>
                      {item.stepTitle}
                    </h3>
                    <p className={'text-lg'}>{parse(item.stepText)}</p>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </SplideTrack>

          <ul class="splide__pagination"></ul>
        </div>
      </Splide>
    </>
  )
}

export default StepSlider
