import React from 'react'
import { Link } from 'gatsby'

const CTA = ({ 
  title,
  link,
 }) => {
  return (
    <div className={'w-screen mx-auto bg-white relative text-3xl sm:text-4xl font-semibold'} id={'cta'}>
      <div
        className={
          'xl-container flex mx-auto flex-wrap py-4 md:py-8 gap-4 items-center justify-center text-center'
        }
      >
        {title}
        {link?.url && (
          <div className={'flex flex-col items-center justify-center'}>
            <a
              target={link?.target}
              href={link.url}
              className={
                'text-orange-main'
              }
            >
              {link.title}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default CTA
