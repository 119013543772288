import React from 'react'

const embedResolver = (url) => {
  if (url.includes('youtube')) {
    return url.replace('watch?v=', 'embed/')
  }
  if(url.includes('youtu.be')) {
    return url.replace('youtu.be', 'youtube.com/embed')
  }
  if (url.includes('vimeo')) {
    return url.replace('vimeo.com', 'player.vimeo.com/video')
  }
  return url
}

const Oembed = ({ url }) => {
  return (
    <iframe
    className={'mx-auto rounded-xl aspect-video'}
    src={embedResolver(url)}
    width={'100%'}
    height={'100%'}
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
    referrerpolicy="strict-origin-when-cross-origin"
    allowfullscreen
  />
  )
}

export default Oembed
