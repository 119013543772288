import React from 'react'
import { Link } from 'gatsby'

const uspItemStyle = 'max-w-[18rem] w-full flex flex-col justify-end items-center border-4 border-blue-dark rounded-[2rem] p-4 lg:p-8 aspect-square'
const uspItemImageStyle = 'w-full h-auto object-contain aspect-square overflow-hidden'
const uspItemTitleStyle = 'text-3xl text-blue-dark font-medium mt-4'
const uspItemTextStyle = 'text-[15px] leading-[15px] md:text-[17px] md:leading-[17px] text-blue-dark font-normal mt-2'

const mapUsps = (item) => {
  if (item?.link?.url) {
    return (
      <Link key={item.text} to={item.link.url} className={uspItemStyle}>
          <img
            className={uspItemImageStyle}
            src={item.icon.sourceUrl}
            alt={item.icon.altText ? item.icon.altText : ''}
          />

          {item.title && (
            <h3
              className={uspItemTitleStyle}
            >
              {item.title}
            </h3>
          )}

          <p
            className={uspItemTextStyle}
          >
            {item.text}
          </p>
      </Link>
    )
  } else {
    return (
      <div
        key={item.text}
        className={uspItemStyle}
      >
        <img
          className={uspItemImageStyle}
          src={item.icon.sourceUrl}
          alt={item.icon.altText ? item.icon.altText : ''}
        />

        {item.title && (
            <h3
              className={uspItemTitleStyle}
            >
              {item.title}
            </h3>
          )}

        <p
          className={uspItemTextStyle}
        >
          {item.text}
        </p>
      </div>
    )
  }
}

const UniqueSellingPoints = ({ data, title = null }) => {
  return (
    <div className={'w-screen mx-auto bg-white relative xl-container mx-auto py-8 md:mb-8'} id={'usps'}>
      {title && (
        <div className={'text-center py-10'}>
          <h2 className={'text-4xl font-bold text-blue-dark'}>{title}</h2>
        </div>
      )}
      <div
        className={
          'flex flex-row flex-wrap justify-center gap-4 lg:gap-16 max-w-screen-lg mx-auto'
        }
      >
      
        {data.map((item) => mapUsps(item))}
      </div>
    </div>
  )
}

export default UniqueSellingPoints
