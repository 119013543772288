import tw, { styled } from 'twin.macro'
import Heading from '../common/heading'
import { css, keyframes } from 'styled-components'

const appearFromBottomAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`

export const Title = styled(Heading)`
  ${css`
    animation: ${appearFromBottomAnimation} 1s cubic-bezier(0.05, 0.4, 0.44, 1);
    animation-delay: 200ms;
    animation-fill-mode: both;
  `}
  ${tw`text-left text-[40px] leading-10 md:text-6xl md:leading-[69px] lg:text-[81px] lg:leading-[89px] `}
`

export const Container = styled.div(({ backgroundImage, notification }) => [
  tw`
  w-full 
  h-screen
  max-h-screen
  relative
  h-[calc(100vh-4rem)]  
  lg:h-[calc(100vh-5rem)]
`,
  backgroundImage && `background-image: url(${backgroundImage}); ${tw`  bg-cover
  bg-no-repeat`}`,
  notification && tw`h-[calc(100vh-7.25rem)] lg:h-[calc(100vh-8.25rem)]`
])
