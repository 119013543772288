import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/hero-alt'
import UniqueSellingPoints from '../components/unique-selling-points-large'
import Aside from '../components/aside'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import CTA from '../components/cta'
import StepSlider from '../components/step-slider'
import Oembed from '../components/oembed/'

const LandingTemplate = ({ data, location }) => {
  const { wpPage: page } = data
  const base = page.template.landingTemplate
  const uspsTitle = base.uniqueSellingPointsTitle
  const usps = base.uniqueSellingPointsList
  const asides = base.asides ?? []
  const videoTitle = base.videoTitle
  const media = base.mediaVideo
  const stepsTitle = base.stepsTitle
  const steps = base.steps

  return (
    <Layout template={page.template.templateName} location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <Hero
        titleTag={base.title.headingTag}
        titleText={base.title.text}
        subtitleTag={base.subtitle.headingTag}
        subtitleText={base.subtitle.text}
        buttons={base.buttons}
        image={base.image}
      />

      {steps.length > 0 && (
        <div className='bg-white'>
          <div className='xl-container mx-auto py-8 lg:py-16'>
          {stepsTitle && (
            <h2 className='text-center text-3xl sm:text-4xl font-bold py-8'>{stepsTitle}</h2>
          )}
          <StepSlider list={steps} />
          </div>
        </div>
      )}

      {media && (
        <div className='bg-blue-lightest'>
          <div className='xl-container mx-auto py-8 lg:py-16'>
          {videoTitle && (
            <h2 className='text-center text-3xl sm:text-4xl font-bold py-8'>{videoTitle}</h2>
          )}
          {media.mediaType === 'image' && media?.image && (
              <img
                className={
                  'mx-auto w-full max-h-[258px] sm:max-h-[540px] lg:max-h-full lg:w-full rounded-xl object-cover overflow-hidden'
                }
                src={media.image.publicUrl}
                alt={media.image.altText ? media.image.altText : ''}
              />
            )}
            {media.mediaType === 'video' && media?.video &&(
              <video
                className={'mx-auto rounded-xl'}
                controls
                height={'100%'}
                width={'100%'}
              >
                <source src={media.video.publicUrl} />
              </video>
            )}
            {media.mediaType === 'oembed' && media?.embed && (
             <Oembed url={media.embed} />
            )}
          </div>
        </div>
      )}

      <UniqueSellingPoints 
        title={uspsTitle}
        data={usps}
       />

      {asides.length > 0 && (
        <div id={'asides'}>
          {asides.map((item, index) => (
            <Aside
              key={index}
              index={index}
              buttons={item.buttonList}
              title={item.title}
              content={item.content}
              media={item.media}
              isOutlined={false}
              id={item.id}
              template={page.template.templateName}
              reverseFlow={true}
            />
          ))}
        </div>
      )}

      <CTA 
        title={base.ctaTitle}
        link={base.ctaLink}
      />
    </Layout>
  )
}

export default LandingTemplate

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        metaDesc
        title
      }
      template {
        ... on WpLandingTemplate {
          templateName
          landingTemplate {
            buttons {
              link {
                url
                title
                target
              }
              variant
            }
            image {
              sourceUrl
              publicUrl
            }
            subtitle {
              headingTag
              text
            }
            title {
              headingTag
              text
            }
            stepsTitle
            steps {
              stepImage {
                sourceUrl
                publicUrl
                title
                altText
              }
              stepTitle
              stepText
            }
            ctaTitle
            ctaLink {
              target
              title
              url
            }
            videoTitle
            mediaVideo {
              mediaType
              image {
                publicUrl
                sourceUrl
                title
              }
              video {
                sourceUrl
                publicUrl
                title
              }
              embed
            }
            asides {
              id
              title
              content
              buttonList {
                variant
                link {
                  target
                  title
                  url
                }
              }
              media {
                mediaType
                image {
                  publicUrl
                  sourceUrl
                  title
                }
                video {
                  sourceUrl
                  publicUrl
                  title
                }
              }
            }
            uniqueSellingPointsTitle
            uniqueSellingPointsList {
              icon {
                sourceUrl
                publicUrl
                title
                altText
              }
              title
              text
              link {
                url
                title
                target
              }
            }
          }
        }
      }
    }
  }
`
